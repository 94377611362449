/**
 * channel模块接口列表
 */
 import request from '@/api/myaxios'; // 导入http中创建的axios实例

 const channel ={
     // 查询子栏目
     queryChildChannel(id){
       return request({
           url:'/api/channel/queryChildChannel/'+id,
           method:'get'
       })
     },
     queryHeadChannel(){
         return request({
             url:'/api/channel/queryHeadChannel',
             method:'get'
         })
     },
     //channel页 栏目内容查询
     queryChannelContent(id) {
        return request({
            url: '/api/channel/queryChannelContent/' + id,
            method: 'get'
        })
    },

    //首页栏目查询
    indexQueryChannel() {
        return request({
            url: '/api/channel/indexQueryChannel',
            method: 'get'
        })
    },
    //查询
    queryChannel(data) {
        return request({
            url: '/api/channel/queryChannel',
            method: 'post',
            data: data
        })
    },

    // 新增栏目
    addChannel(data){
        return request({
            url:'/api/channel/addChannel',
            method: 'post',
            data:data
        })
    },

    // 修改栏目
    editChannel(data){
        return request({
            url:'/api/channel/editChannel',
            method:'post',
            data: data
        })
    },

    //删除栏目
    delChannel(id) {
        return request({
            url:'/api/channel/delChannel/'+ id,
            method:'get',
        })
    }
 }

 export default channel