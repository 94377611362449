<template>
  <!--nav-->
  <div class="nav">
    <van-nav-bar  @click-left="onClickLeft" @click-right="showPopup">
       <template v-if="data.back" #left>
        <van-icon name="arrow-left" size="18" />
      </template>
      <template #title>
        <img v-if="!data.sign" :src="logoPath" style="height: 30px;vertical-align:middle;padding: 2px 0">
        <span  v-else >{{data.title}}</span>
      </template>
      <template #right>
        <van-icon name="ellipsis" size="18" />
      </template>
    </van-nav-bar>
  </div>
  <!--导航-->
  <van-popup v-model:show="show" position="right" :overlay-style="{background:'transparent'}" closeable :style="{ height: '100%', width:'45%', 'background-color':'#000000','color':'#fff', opacity: '0.95' }">
    <div class="title">
      <ul>
        <li><a @click="viewPage('index')">首页</a></li>
        <li v-for="c in channelList"><a @click="viewChannel(c.id, c.name)">{{c.name}}</a></li>
        <li><a @click="showSc">官方商城</a></li>
        <li><a @click="viewPage('feedback')">信息留言</a></li>
      </ul>
    </div>
  </van-popup>
<!--超链接-->
  <van-popup v-model:show="linkShow">
    <div class="link-box">
      <ul>
        <li v-for="l in linkList"><a @click="jump2Url(l.url)">{{l.name}}</a></li>
      </ul>
    </div>
  </van-popup>
</template>

<script>
import {ref, onMounted, computed,nextTick} from 'vue'
import { useRoute, useRouter,onBeforeRouteUpdate } from 'vue-router';
import variableApi from "@/api/system/variable";
import {Toast} from "vant";
import config from "@/api/config";
import channelApi from '@/api/info/channel'
import friendlinkApi from '@/api/info/friendlink'
export default {
  props:{
    data: {
      sign: Boolean,
      title: String,
      back:Boolean
    },
  },

  setup(props){
    const router = useRouter();
    const logoPath=ref('');// 网站logo路径

    const channelList= ref([]);
    const linkList= ref([]);
    const childChannelMap= ref({});

    const queryHeadChannel=()=> {
      channelApi.queryHeadChannel().then(res => {
        channelList.value = res.data.data;
        // 循环获取自己栏目
        /*for(let i=0;i<channelList.value.length;i++){
          let cid=channelList.value[i].id;
          channelApi.queryChildChannel(cid).then((response) => {
            if(response.data.code==1){
              let childList = response.data.data;
              // 当有子级时放入map
              if(childList.length>0){
                childChannelMap[cid]=childList;
              }else{
                childChannelMap[cid]=[];
              }
            }
          });
        }*/
      })
    }

    const data = computed(()=>{
      return props.data;
    })

    const onClickLeft = () => history.back();


    const show = ref(false);
    const showPopup = ()=>{
      show.value = true;
    };

    const linkShow = ref(false);

    //栏目页
    const viewChannel = (channelId, channelName)=> {
      router.push({
        path: '/h5/channel',
        query: {
          channelName: channelName,
          channelId: channelId
        }
      })
      show.value=false;
    }

    //跳转页面
    const viewPage = (path) => {
      router.push({
        path: '/h5/'+ path})
    };

    //外链跳转
    const jump2Url = (url) => {
      if(url!=null&&url!=''){
        window.open(url);
      }
    };
    // 查看商城外链
    const showSc=()=>{
      // 显示
      linkShow.value = true;
    }

    // 获取友情链接
    const queryFriendLink=(groupName)=> {
      friendlinkApi.queryLink({groupName:groupName}).then((res) => {
        linkList.value = res.data.data;
      })
    }

    onMounted (()=>{
      //获取系统变量-logo
      variableApi.queryVarNumVariable({varNum: "logo"}).then(res => {
        if (res.data.code == 1) {
          logoPath.value = config.server + res.data.data.logo;
        }
      })

      // 获取栏目
      queryHeadChannel();

      // 获取友情链接
      queryFriendLink('官方商城');
    });

    return{
      logoPath,
      data,
      show,
      linkShow,
      showPopup,
      onClickLeft,
      viewChannel,
      viewPage,
      channelList,
      linkList,
      jump2Url,
      showSc,
    }
  }
}
</script>

<style scoped>
.nav{
  padding-left: 0px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
}
.van-nav-bar{
  background-color: #000000;
}
:deep().van-nav-bar__title, :deep().van-nav-bar .van-icon{
  color: white;

}

.title{
   height:100%; height:auto; margin-top:46px;
}
.title li{
  text-align: center;
  height: 3rem;
  line-height: 3rem;
  color: white;
  
}
.title a{
  display: block;
  overflow:hidden;
  font-size: 1em;
}
.link-box{
  background-color: #757c84;
  color: #FFFFFF;
}
.link-box ul{
  padding: 20px;
}
.link-box ul li{
  padding: 10px 0;
  color: #FFFFFF;
}

</style>