/**
 * 友情链接模块接口列表
 */
import request from '@/api/myaxios'; // 导入http中创建的axios实例

const friendlink = {
    //前台，点击增加浏览量
    click(id){
        return request({
            url:'/api/info/friendlink/click/' + id,
            method:'get'
        })
    },
    // 前台获取链接
    queryLink(data){
        return request({
            url:'/api/info/friendlink/queryLink',
            method:'post',
            data: data
        })
    },
    //行内编辑名称，地址，排序
    rowEditName(data){
        return request({
            url: '/api/info/friendlink/rowEditName',
            method: 'post',
            data: data
        })
    },
    // 友情链接查询
    queryFriendlink(data) {
        return request({
            url: '/api/info/friendlink/queryFriendlink',
            method: 'post',
            data: data
        });
    },
    // 删除友情链接
    delFriendlink(id) {
        return request({
            url: '/api/info/friendlink/delFriendlink/'+id,
            method: 'get'
        });
    },
    // 新增友情链接
    saveFriendlink(data) {
        return request({
            url: '/api/info/friendlink/saveFriendlink',
            method: 'post',
            data: data
        });
    },
}

export default friendlink;